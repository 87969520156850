*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#wrapper {
  /* min-width: 1200px; */
  margin-left: 0px;
  color: #000;
  font: 16px Verdana, sans-serif;
  background: #fff;
  background-color: inherit;
  /*-webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;*/
  position: relative;
  margin-top: 1em;
}

#wrapper .branch {
  position: relative;
  left: -20px;
  margin-left: 174px;
}

#wrapper .branch:before {
  content: "";
  /* width: 50px; */
  /* border-top: 1px solid #000; */
  /* position: absolute; */
  /* left: -100px; */
  /* top: 50%; */
  /* margin-top: 1px; */
}

#wrapper .entry {
  position: relative;
  top: 0px;
  min-height: 24px;
}

#wrapper .entry:before {
  content: "";
  height: 100%;
  border-left: 2px solid #000;
  position: absolute;
  /* //bottom: -50%; */
  left: -5px;
}

#wrapper .entry:after {
  content: "";
  width: 6px;
  border-top: 2px solid #000;
  position: absolute;
  left: -5px;
  top: 50%;
  margin-top: 1px;
}

#wrapper .entry:first-child:before {
  width: 7px;
  height: 50%;
  top: 50%;
  margin-top: 1px;
  border-radius: 10px 0 0 0;
}

#wrapper .entry:first-child:after {
  height: 10px;
  border-radius: 10px 0 0 0;
}

#wrapper .entry:last-child:before {
  width: 10px;
  height: 50%;
  border-radius: 0 0 0 10px;
}

#wrapper .entry:last-child:after {
  height: 10px;
  border-top: none;
  border-bottom: 2px solid #000;
  border-radius: 0 0 0 10px;
  margin-top: -12px;
}

#wrapper .entry.sole:before {
  display: none;
}

#wrapper .entry.sole:after {
  width: 50px;
  height: 0;
  margin-top: 1px;
  border-radius: 0;
}

#wrapper .label {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  max-width: 150px;
  line-height: 11px;
  text-align: center;
  border: 0px solid #000;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -13px;
  font-size: 0.83em;
  min-height: 23px;
}

#wrapper .label.fem {
  color: #ffffff;
}

#wrapper .label.masc {
  color: #ffffff;
}

#wrapper .fem {
  background-color: #d14141;
}

#wrapper .masc {
  background-color: #2d79a8;
}

a:link {
  text-decoration: none;
  color: inherit;
}