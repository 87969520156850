body {
    overflow: auto !important;
}

@media print {

    /* html,
    body {
        height: auto !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    } */

    /* div#print-document {
        transform: scale(.7);
    } */


    #body-arvore {
        zoom: 70%;
    }

    /* @page { size: 440mm 130.5mm; } */
    @page {
        size: A4 landscape;
    }

    /* @page { size: landscape; } */
}